.footer_section {
    padding-top: var(--size-huge);
    padding-bottom: var(--size-medium);
}
.footer_logo {
    text-align: center;
}
.footer_logo_img {
    height: 80px;
}
.footer_nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid white;
    padding-top: var(--size-large);
    padding-bottom: var(--size-large);
    margin-bottom: var(--size-medium);
}
.footer_link {
    text-transform: initial;
    color: var(--color-white);
    font-size: 2rem;
    text-decoration: none;
    padding: 1rem 2rem;
}
.footer_link:hover {
    color: var(--color-greenLight);
}
.footer_text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}
.footer_text p {
    font-size: 1.5rem;
    font-weight: 400;
    color: #d9dbe1;
}
.footer_copyright {
    text-align: left;
}
.footer_rrss {
    text-align: right;
}
.footer_rrss a {
    color: var(--color-white);
    text-decoration: none;
    font-size: 2rem;
    padding-left: 1rem;
}
.footer_rrss a:hover {
    color: var(--color-greenLight);
}

/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .footer_logo_img {
        height: 32px;
    }
}

/*TABLET*/
@media only screen and (max-width: 1024px) {
    .footer_section {
        padding-top: var(--size-large);
        padding-bottom: var(--size-medium);
    }
    .footer_nav {
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
        margin-bottom: var(--size-medium);
    }
    .footer_link {
        text-transform: initial;
        color: var(--color-white);
        font-size: 1.8rem;
        text-decoration: none;
        padding: 0.5rem 2rem;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .footer_logo_img {
        height: 24px;
    }
    .footer_nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer_link {
        font-size: 1.5rem;
        padding: 1rem 1rem;
    }
    .footer_text {
        grid-template-columns: 1fr;
    }
    .footer_copyright {
        text-align: center;
        grid-column: 1;
        grid-row: 2;
    }
    .footer_rrss {
        text-align: center;
        grid-column: 1;
        grid-row: 1;
        padding-bottom: var(--size-medium);
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
}
