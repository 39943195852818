.download_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding-right: var(--size-xxhuge);
    padding-left: var(--size-xxhuge);
}
.download_boxLeft {
    padding-top: var(--size-huge);
    padding-bottom: var(--size-huge);
    padding-right: var(--size-huge);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.download_boxLeft > div:nth-child(3) {
    padding: 6% 0 0 0;
    text-align: left;
}
.download_title {
    padding-bottom: var(--size-small);
}
.download_download {
    padding-top: var(--size-large);
}
.download_download a {
    cursor: pointer;
}
.download_download a img {
    height: 60px;
    padding-top: 1rem;
    padding-right: 2rem;
}
.download_boxRight {
    padding-left: var(--size-large);
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
}
.download_boxRight img {
    width: 50%;
    height: auto;
}
.download_boxRight > img:nth-child(1) {
    margin-top: -140px;
}
.download_boxRight > img:nth-child(2) {
    margin-bottom: -160px;
}
/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .download_section {
        padding-right: var(--size-medium);
        padding-left: var(--size-huge);
    }
    .download_boxLeft {
        padding-right: var(--size-large);
        display: flex;
        flex-direction: column;
    }
    .download_download {
        padding-top: var(--size-large);
    }
    .download_boxRight {
        padding-left: var(--size-large);
    }
}

/*TABLET*/
@media only screen and (max-width: 1024px) {
    .download_section {
        padding-right: 0;
        padding-left: var(--size-large);
    }
    .download_boxLeft {
        padding-right: 0;
    }
    .download_download {
        padding-top: var(--size-medium);
    }
    .download_boxRight {
        padding-left: 0;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .download_section {
        display: none;
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
}
