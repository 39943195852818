/*--FORMULARIO--*/
.sectionForm_form_name,
.sectionForm_form_email,
.sectionForm_form_subject {
    height: 120px;
}

.sectionForm_form_texto,
.sectionForm_form_submit {
    grid-column: span 2;
}

.sectionForm_form div {
    align-items: center;
    height: auto;
    line-height: 1.6;
}

.sectionForm_form div label {
    color: var(--color-black);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    text-align: left;
}
.sectionForm_form div {
    text-align: left;
}
.sectionForm_form div input[type='text'],
.sectionForm_form div input[type='email'],
.sectionForm_form div input[type='subject'],
.sectionForm_form div textarea {
    background-color: var(--color-white);
    border-radius: 8px;
    border: 1px solid black;
    font-family: 'Mulish' !important;
    width: 100%;
    padding: 2.5% 4%;
    gap: 10px;
    margin-bottom: 15px;
    position: relative;
}
.sectionForm_form_submit p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}

.sectionForm_form_submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start !important;
}
.sectionForm_form_submit input {
    border: rgb(28, 192, 155);
    background-color: rgb(28, 192, 155);
    color: var(--color-white);
    padding: 1vh 2vw;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
}

.sectionForm_form_submit input:hover {
    cursor: pointer;
    border: rgb(16, 112, 90);
    background-color: rgb(16, 112, 90);
}
#errorName,
#errorEmail,
#errorSubject,
#errorComment {
    display: block;
    color: #b40e0e;
    margin: 1% 0 2% 0 !important;
    padding: 0;
    font-size: 1.2rem;
}
.invalid div input,
.invalid div textarea {
    border: 1px solid #b40e0e !important;
    background-color: #fddddd !important;
    margin-bottom: 0 !important;
}
input:disabled,
input:disabled:hover {
    background-color: #ccc;
    color: #292929;
    border-color: #ccc;
    cursor: auto;
}
.boxText {
    text-align: center;
    display: none;
}

.textActivo {
    display: block;
}
.textActivo p {
    color: rgb(28, 192, 155);
    font-size: 2.2rem;
    margin-top: 2rem;
    padding: 1rem 0;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 767px) {
    .sectionForm_form_submit input {
        width: 100%;
    }
    .textActivo p {
        font-size: 1.8rem;
    }
}
@media only screen and (max-width: 576px) {
}
