.sectionHeader {
    padding: 0;
    height: 100vh;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-rows: 80px 1fr;
    box-sizing: border-box;
}
.header_main {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: var(--size-xhuge);
    width: 50%;
    height: 100% !important;
    box-sizing: border-box;
    overflow: hidden;
}
.header_main h1 {
    font-size: 8rem;
    line-height: 10rem;
    font-weight: bold;
}
.header_main > div {
    padding-top: var(--size-medium);
    padding-bottom: var(--size-medium);
}

/*HORIZONTAL*/
.sectionHeader_horizontal {
    background-image: url('assets/img/headerLEFT.webp');
    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position: bottom 50% right -400px;
}
.sectionHeader_horizontal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, white 20%, transparent 40%);
    z-index: -1;
}
/*VERTICAL*/
.sectionHeader_vertical {
    min-height: auto;
}
.sectionHeader_vertical > .header_vertical_fondo {
    grid-column: 1;
    grid-row: 2;
    background-image: url('assets/img/headerBOTTOM.webp');
    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position: bottom 50% right -400px;
}
.sectionHeader_vertical > .header_main {
    grid-column: 1;
    grid-row: 3;
    z-index: 2;
    position: relative;
    margin-top: 0;
    padding-top: 0;
    width: 100%;
}
.sectionHeader_vertical > .header_main > h1 {
    text-align: center;
    padding: 0;
}
.sectionHeader_vertical > .header_main > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-bottom: 0;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .sectionHeader {
        background-size: auto 130%;
        background-position: bottom 50% right -350px;
        grid-template-rows: 80px 1fr;
    }
    .sectionHeader::after {
        background: linear-gradient(to right, white 20%, transparent 50%);
    }
    .header_main {
        padding-left: var(--size-huge);
        width: 65%;
    }
    .header_main h1 {
        font-size: 6rem;
        line-height: 7rem;
    }
}
@media only screen and (max-width: 1400px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 5%, transparent 50%);
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 30% right 60%;
        background-size: auto 140%;
    }
    .header_main {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .header_main > div {
        padding-top: var(--size-large);
    }
}
@media only screen and (max-width: 1024px) {
    .sectionHeader {
        grid-template-rows: 116px 1fr;
        background-size: auto 100%;
        background-position: bottom 0% right 0%;
    }
    .sectionHeader::after {
        background: linear-gradient(to right, white 10%, transparent 40%);
    }
    .header_main {
        padding-left: var(--size-large);
    }
    .header_main h1 {
        font-size: 3.8rem;
        line-height: 4.8rem;
    }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 5%, transparent 50%);
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 30% right 60%;
        background-size: auto 120%;
    }
    .header_main {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
    }
    .header_main h1 {
        font-size: 5.5rem;
        line-height: 6.5rem;
    }
}
@media only screen and (max-width: 767px) {
    .sectionHeader {
        grid-template-rows: 56px 1fr;
        background-size: auto 100%;
        background-position: bottom 0% right 0%;
    }
    .sectionHeader::after {
        background: linear-gradient(to right, white 20%, transparent 50%);
    }
    .header_main {
        padding-left: var(--size-large);
        width: 65%;
    }
    .header_main h1 {
        font-size: 3.3rem;
        line-height: 4.3rem;
    }
    .header_main > div {
        padding-top: var(--size-small);
    }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 30%, transparent 50%);
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 30% right 60%;
        background-size: auto 120%;
    }
    .header_main {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
    }
    .header_main h1 {
        font-size: 4rem;
        line-height: 5rem;
    }
}
@media only screen and (max-width: 576px) {
    .sectionHeader {
        background-position: top 0% left 100%;
        background-size: 135%;
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 30%, transparent 50%);
    }
    .header_main h1 {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
    .header_main > div {
        padding: 1.5rem 0 2rem 0;
    }
}
@media only screen and (max-width: 576px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 0% right 60%;
        background-size: auto 120%;
    }
    .header_main > div {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
    }
}
@media only screen and (max-width: 480px) {
    .sectionHeader {
        background-position: bottom 0% left 100%;
        background-size: 135%;
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 25%, transparent 50%);
    }
}
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 15% right 58%;
        background-size: auto 110%;
    }
    .header_main h1 {
        font-size: 3rem;
        line-height: 4rem;
    }
}
@media only screen and (max-width: 390px) {
    .sectionHeader {
        background-position: bottom 0 left 100%;
        background-size: 135%;
        grid-template-rows: 56px 220px auto;
    }
    .sectionHeader::after {
        background: linear-gradient(to top, white 45%, transparent 60%);
    }
    .header_main h1 {
        font-size: 2.8rem;
        line-height: 3.8rem;
    }
    .header_main > div {
        padding: 1rem 0 2rem 0;
    }
}
@media only screen and (max-width: 390px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 15% right 60%;
        background-size: auto 110%;
    }
    .header_main h1 {
        font-size: 2.7rem;
        line-height: 3.7rem;
    }
    .header_main > div {
        padding-top: var(--size-medium);
    }
}
@media only screen and (max-width: 320px) and (orientation: portrait) {
    .sectionHeader {
        grid-template-rows: 56px 1fr auto;
        min-height: auto;
    }
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 15% right 56%;
        background-size: auto 100%;
    }
    .header_main h1 {
        font-size: 1.9rem;
        line-height: 2.6rem;
    }
}
@media only screen and (max-width: 281px) and (orientation: portrait) {
    .sectionHeader_vertical > .header_vertical_fondo {
        background-position: bottom 15% right 56%;
        background-size: auto 100%;
    }
    .header_main h1 {
        font-size: 1.7rem;
        line-height: 2.3rem;
    }
}
