#root {
    white-space: pre-line;
}

:root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-greenDark: #1cc09b;
    --color-greenLight: #bcf6e9;
    --color-greenBlue: #276c77;

    --size-none: 0;
    --size-xtiny: 4px;
    --size-tiny: 8px;
    --size-mini: 12px;
    --size-small: 16px;
    --size-base: 20px;
    --size-medium: 24px;
    --size-large: 32px;
    --size-xlarge: 48px;
    --size-huge: 64px;
    --size-xhuge: 128px;
    --size-xxhuge: 192px;
}
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.6rem;
}
body {
    font-family: 'Mulish' !important;
    font-weight: 400;
    min-height: 100vh;
    max-width: 100vw;
}
h1 {
    font-family: 'Mulish';
    font-size: 10rem;
    font-weight: 800;
    line-height: 12rem;
    padding-bottom: 2rem;
    text-align: left;
}
h2 {
    font-family: 'Mulish';
    font-size: 7rem;
    font-weight: 800;
    line-height: 9rem;
}
h3 {
    font-family: 'Mulish';
    font-size: 5rem;
    font-weight: 800;
    line-height: 6.5rem;
}
h5 {
    color: #18191f;
    font-family: 'Mulish';
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    text-align: left;
    padding: 25px 0 20px 0;
}
/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    h1 {
        font-size: 8rem;
        line-height: 10rem;
        padding-bottom: 3rem;
    }
    h2 {
        font-size: 5.5rem;
        line-height: 7rem;
    }
    h3 {
        font-size: 4rem;
        line-height: 5rem;
    }
}

/*TABLET*/
@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 5rem;
        line-height: 6rem;
        padding-bottom: 2rem;
    }
    h2 {
        font-size: 5rem;
        line-height: 6.5rem;
    }
    h3 {
        font-size: 3.8rem;
        line-height: 4rem;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    h1 {
        font-size: 5rem;
        line-height: 6rem;
        padding-bottom: 2rem;
    }
    h2 {
        font-size: 3.5rem;
        line-height: 5rem;
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
    h1 {
        font-size: 3rem;
        line-height: 4rem;
        padding-bottom: 2rem;
    }
    h2 {
        font-size: 2.6rem;
        line-height: 4rem;
    }
}
