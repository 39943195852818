.aboutUs_section {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-auto-rows: minmax(auto, auto);
    padding-right: var(--size-xxhuge);
    padding-left: var(--size-xxhuge);
}
.box_aboutUs_left {
    grid-column: 1;
    grid-row: 1 / 3;
    background-image: url('assets/img/aboutUs.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    margin-right: var(--size-huge);
}
.box_aboutUs_right {
    grid-column: 2;
    grid-row: 2;
    padding-bottom: var(--size-huge);
    align-content: end;
}
.box_aboutUs_right_title {
    grid-column: 2;
    grid-row: 1;
    padding-top: var(--size-huge);
    padding-bottom: var(--size-large);
    align-content: start;
}

/*MEDIA QUERYS*/
/*TABLET*/
@media only screen and (max-width: 1400px) {
    .aboutUs_section {
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
    }
}
@media only screen and (max-width: 1024px) {
    .aboutUs_section {
        grid-template-columns: 5fr 6fr;
        padding-right: var(--size-large);
        padding-left: var(--size-large);
    }
    .box_aboutUs_left {
        margin-right: var(--size-large);
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .aboutUs_section {
        grid-template-columns: 1fr;
        padding-right: 0;
        padding-left: 0;
    }
    .box_aboutUs_left {
        grid-column: 1;
        grid-row: 2;
        height: 375px;
        margin-right: 0;
    }
    .box_aboutUs_right {
        grid-column: 1;
        grid-row: 3;
        align-content: end;
        padding-top: var(--size-medium);
        padding-bottom: var(--size-large);
        padding-right: var(--size-large);
        padding-left: var(--size-large);
    }
    .box_aboutUs_right_title {
        grid-column: 1;
        grid-row: 1;
        padding-top: var(--size-medium);
        align-content: center;

        padding-right: var(--size-large);
        padding-left: var(--size-large);
    }
    .box_aboutUs_right_title h2 {
        text-align: center !important;
    }
    .box_aboutUs_right_title p {
        text-align: center !important;
    }
}
