.problem_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(auto, auto);
}
.problem_boxTitle {
    grid-column: 1/3;
    grid-row: 1;
    padding-bottom: var(--size-huge);
}
.problem_boxLeft {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    padding-right: var(--size-huge);
    justify-content: center;
    z-index: 1;
}
.problem_boxLeft p:nth-child(1) {
    padding-bottom: var(--size-large);
}

.problem_boxRight {
    margin-left: var(--size-medium);
    background-image: url('assets/img/problem.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom 0 right 50%;
    padding: 0;
    border-radius: 4px;
    z-index: 1;
}
.problem_boxRight > img {
    width: auto;
    height: 100%;
    border-radius: 4px;
}
/*fondo*/
.problemBackRight_IMG {
    height: 380px;
    position: absolute;
    right: 0;
}
.problemBackLeftTop_IMG {
    height: 160px;
    position: absolute;
    left: 0;
}
.problemBackLeftDownCircle_IMG {
    height: 75px;
    position: absolute;
    left: 20px;
    bottom: 60px;
}
.problemBackLeftDownCircleBig_IMG {
    width: 285px;
    position: absolute;
    left: 17px;
    bottom: -186px;
}
.problemBackLeftMobilNoFull_IMG,
.problemBackLeftMobilFull_IMG {
    display: none;
}

/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .problem_boxTitle {
        padding-bottom: var(--size-large);
    }
    .problem_boxLeft {
        padding-right: var(--size-small);
    }
    .problem_boxLeft p:nth-child(1) {
        padding-bottom: var(--size-large);
    }
    .problem_boxRight {
        padding-left: var(--size-small);
    }
    /*imagenes*/
    .problem_boxRight_img1 {
        width: 90%;
    }
    .problem_boxRight_img2 {
        width: 85%;
    }
    /*fondo*/
    .problemBackRight_IMG {
        height: 280px;
        right: 0;
    }
    .problemBackLeftTop_IMG {
        height: 90px;
        left: 0;
        top: 5%;
    }
    .problemBackLeftDownCircle_IMG {
        height: 45px;
        bottom: 20px;
    }
    .problemBackLeftDownCircleBig_IMG {
        width: 200px;
        bottom: -130px;
    }
    .problemBackLeftMobilNoFull_IMG,
    .problemBackLeftMobilFull_IMG {
        display: none;
    }
}
/*TABLET*/
@media only screen and (max-width: 1024px) {
    .problem_boxLeft {
        padding-right: var(--size-small);
    }
    /*fondo*/
    .problemBackRight_IMG {
        height: 175px;
        top: 5%;
    }
    .problemBackLeftTop_IMG {
        height: 62px;
        top: 5%;
    }
    .problemBackLeftDownCircle_IMG {
        display: none;
    }
    .problemBackLeftDownCircleBig_IMG {
        width: 143px;
        bottom: -93px;
    }
    .problemBackLeftMobilNoFull_IMG,
    .problemBackLeftMobilFull_IMG {
        display: none;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .problem_section {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(auto, auto);
        padding-bottom: var(--size-medium);
    }
    .problem_boxTitle {
        grid-column: 1;
        grid-row: 1;
    }
    .problem_boxLeft {
        grid-column: 1;
        grid-row: 3;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: var(--size-medium);
    }
    .problem_boxRight {
        margin-left: 0;
        height: 300px;
    }
    .problem_boxRight div:nth-child(1) {
        text-align: center !important;
        width: 85%;
    }
    /*fondo*/
    .problemBackRight_IMG {
        height: 225px;
        top: 8%;
    }
    .problemBackLeftTop_IMG,
    .problemBackLeftDownCircleBig_IMG {
        display: none;
    }
    .problemBackLeftMobilNoFull_IMG {
        display: block;
        height: 6rem;
        position: absolute;
        left: 2%;
        top: 10%;
    }
    .problemBackLeftMobilFull_IMG {
        display: block;
        height: 7rem;
        position: absolute;
        left: 70px;
        top: 40%;
        display: none;
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
    /*fondo*/
    .problem_boxRight {
        height: 200px;
    }
    .problemBackLeftTop_IMG,
    .problemBackLeftDownCircleBig_IMG {
        display: none;
    }
    .problemBackLeftMobilNoFull_IMG {
        display: block;
        height: 35px;
        position: absolute;
        left: 0;
        top: 10%;
    }
    .problemBackLeftMobilFull_IMG {
        display: block;
        height: 40px;
        position: absolute;
        left: 40px;
        top: 25%;
        display: none;
    }
}
