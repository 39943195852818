/*NAVEGADOR*/
.nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 80px;
    padding-left: var(--size-huge);
    padding-right: var(--size-huge);
    display: grid;
    grid-template-columns: auto 1fr 100px 150px;
    grid-row: auto;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
}
.navColorChange {
    background-color: rgba(255, 255, 255, 1);
}

.nav_logo {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
}
.nav_logo_img {
    height: 32px;
}
.nav_nav {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.nav_rrss {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav_rrss a {
    color: #2b383b;
    font-size: 2.3rem;
    padding-left: 2rem;
    cursor: pointer;
}
.nav_rrss a:hover {
    color: var(--color-black);
}

.nav_language {
    grid-row: 1;
    grid-column: 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.button_language {
    font-family: 'Mulish', sans-serif;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2b383b;
}
.button_language span {
    padding-left: 10px;
    text-transform: uppercase;
}
.nav_nav_link {
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    color: #2b383b;
    cursor: pointer !important;
    padding: 0 1%;
    margin: 0 2%;
    height: 100%;
    align-items: center;
    display: flex;
    text-align: center;
    text-decoration: none;
}
.nav_nav_link_active {
    color: #1cc09b;
    border-bottom: #1cc09b 2px solid;
}
.nav_burger {
    display: none;
}
.nav_MenuLanguage {
    position: absolute;
    top: 70px;
    right: 5px;
    z-index: 2;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1%;
    background-color: var(--color-white);
}
.button_languageOption {
    font-family: 'Mulish', sans-serif;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    margin: 2% 0%;
    padding: 6% 5%;
    border: none;
    background-color: var(--color-white);
    cursor: pointer;
}
.button_languageOption_active {
    border: 1px solid rgb(28, 192, 155);
    color: rgb(28, 192, 155);
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    /*NAVEGADOR*/
    .nav {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        grid-template-columns: auto 1fr 80px 110px;
    }
    .nav_nav_link {
        font-size: 14px;
        padding: 0 2%;
        margin: 0;
    }
    .button_language {
        font-size: 14px;
    }
    .nav_rrss a img {
        height: 20px !important;
    }
}
@media only screen and (max-width: 1023px) {
    /*NAVEGADOR*/
    .nav {
        padding-top: var(--size-medium);
        padding-left: var(--size-large);
        padding-right: var(--size-large);
        grid-template-columns: 1fr 100px 100px;
        grid-auto-rows: auto;
        height: 116px;
    }
    .navPages {
        padding-top: 0;
        height: 80px;
        grid-template-columns: auto 1fr 100px 150px;
    }
    .nav_logo {
        grid-column: 1;
        grid-row: 1;
    }
    .nav_logo_img {
        height: 32px;
    }
    .nav_nav {
        grid-column: 1/4;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .nav_nav_link {
        font-size: 12px;
        padding: 2% 0 2% 0;
    }
    .button_language {
        font-size: 12px;
    }
    .nav_rrss {
        grid-column: 2;
        grid-row: 1;
    }
    .nav_language {
        grid-column: 3;
        grid-row: 1;
    }
    .nav_MenuLanguage {
        top: 100px;
    }
    /*navegador paginas*/
    .navPages .nav_nav {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 5%;
    }
    .navPages .nav_rrss {
        grid-column: 3;
        grid-row: 1;
    }
    .navPages .nav_language {
        grid-column: 4;
        grid-row: 1;
    }
}
@media only screen and (max-width: 767px) {
    /*NAVEGADOR*/
    .nav {
        padding-top: 0;
        padding-left: var(--size-small);
        padding-right: var(--size-small);
        height: 56px;
        grid-template-columns: 1fr auto;
        background-color: #ffffff;
    }
    .nav_logo {
        grid-column: 1;
        grid-row: 1;
    }
    .nav_logo_img {
        height: 24px;
    }
    .nav_nav {
        position: fixed;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: var(--color-white);
        min-height: 50%;
        width: 70%;
        top: 56px;
        right: 0;
        border-top: 1px solid black;
        padding: 3% 5% 0 5%;
        z-index: 100;
    }
    .nav_nav_link {
        font-size: 14px;
        padding-bottom: var(--size-large);
    }
    .nav_nav_link_active {
        border-bottom: none;
    }
    .nav_language {
        position: fixed;
        background-color: var(--color-white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 50%;
        width: 70%;
        bottom: 0;
        right: 0;
        padding: 12% 5% 0 5%;
        z-index: 99;
    }
    .button_language {
        width: 100%;
        text-align: left;
        border-top: 1px solid gray;
        padding-top: var(--size-medium);
        padding-bottom: var(--size-small);
        font-size: 14px;
    }

    .nav_rrss {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--color-white);
        height: auto;
        width: 70%;
        bottom: 0px;
        right: 0px;
        padding: 5%;
        z-index: 100;
    }

    .nav_burger {
        grid-column: 1;
        grid-row: 2;
        justify-content: flex-end;
        align-self: center;
        display: flex;
        grid-column: 2;
        grid-row: 1;
    }
    .button_navMobile {
        border: none !important;
        background-color: transparent !important;
    }
    .nav_burger img {
        height: 24px;
    }
    /*menú inglés*/
    .nav_MenuLanguage {
        position: initial;
        height: auto;
        width: 100%;
    }
    .button_languageOption {
        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin: 2% 5%;
        padding: 3% 5%;
    }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
    /*NAVEGADOR*/
    .nav {
        padding-top: 0;
        padding-left: var(--size-small);
        padding-right: var(--size-small);
        height: 56px;
        grid-template-columns: 1fr auto;
        background-color: #ffffff;
    }
    .nav_logo {
        grid-column: 1;
        grid-row: 1;
    }
    .nav_logo_img {
        height: 24px;
    }
    .nav_nav {
        position: fixed;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: var(--color-white);
        min-height: 50%;
        width: 70%;
        top: 56px;
        right: 0;
        border-top: 1px solid black;
        padding: 3% 5% 0 5%;
        z-index: 100;
    }
    .nav_nav_link {
        font-size: 14px;
        padding-bottom: var(--size-large);
    }
    .nav_language {
        position: fixed;
        background-color: var(--color-white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 50%;
        width: 70%;
        bottom: 0;
        right: 0;
        padding: 12% 5% 0 5%;
        z-index: 99;
    }
    .button_language {
        width: 100%;
        text-align: left;
        border-top: 1px solid gray;
        padding-top: var(--size-medium);
        padding-bottom: var(--size-small);
        font-size: 14px;
    }

    .nav_rrss {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--color-white);
        height: auto;
        width: 70%;
        bottom: 0px;
        right: 0px;
        padding: 5%;
        z-index: 100;
    }

    .nav_burger {
        grid-column: 1;
        grid-row: 2;
        justify-content: flex-end;
        align-self: center;
        display: flex;
        grid-column: 2;
        grid-row: 1;
    }
    .button_navMobile {
        border: none !important;
        background-color: transparent !important;
    }
    .nav_burger img {
        height: 24px;
    }
    /*menú inglés*/
    .nav_MenuLanguage {
        position: initial;
        height: auto;
        width: 100%;
    }
    .button_languageOption {
        width: 90%;
        text-align: center;
        border-radius: 5px;
        margin: 2% 5%;
        padding: 3% 5%;
    }
}
@media only screen and (max-width: 576px) {
    .nav_language {
        padding: 20% 5% 0 5%;
    }
}
