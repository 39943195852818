.login_title {
    padding-top: 70px;
}
.login_title > h1 {
    text-align: center;
    font-size: 40px;
    color: #1cc09b;
    font-weight: 800;
    padding: 0;
    margin-bottom: 3%;
}
.login_section {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 10% 5% 10%;
    gap: 3%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 100%;
    max-width: 100%;
}

/*fondo*/
.loginRightBottom_IMG {
    height: 185px;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
}
.loginRightTop_IMG {
    height: 160px;
    position: absolute;
    top: 20%;
    right: 70px;
    z-index: 3;
}
.loginLeftTop_IMG {
    height: 75px;
    position: absolute;
    top: 12%;
    left: 20%;
    z-index: 3;
}
.loginLeftCenter_IMG {
    position: absolute;
    top: 40%;
    left: -80px;
    width: 265px;
    z-index: 3;
}
.loginLeftBottom_IMG {
    position: absolute;
    bottom: 20%;
    left: -40px;
    width: 80px;
    z-index: 3;
}
.loginCenterBottom_IMG {
    position: absolute;
    bottom: 3%;
    left: 40%;
    width: 80px;
    z-index: 3;
}

/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
}
/*TABLET*/
@media only screen and (max-width: 1024px) {
    .login_title {
        padding-top: 80px;
    }
    .login_title > h1 {
        font-size: 40px;
        margin-bottom: 4%;
    }
    .login_section {
        padding: 0 1% 3% 1%;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .login_title {
        padding-top: 70px;
    }
    .login_title > h1 {
        font-size: 40px;
        margin-bottom: 4%;
    }
    .login_section {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 0;
        column-gap: 0;
        min-height: 0; /* NEW */
        min-width: 0;
        padding: 0 5% 0 5%;
    }
    /*fondo*/
    .loginRightBottom_IMG,
    .loginRightTop_IMG,
    .loginLeftTop_IMG,
    .loginLeftCenter_IMG,
    .loginLeftBottom_IMG,
    .loginCenterBottom_IMG {
        display: none;
    }
}
@media only screen and (max-width: 576px) {
    .login_title {
        padding-top: 60px;
    }
    .login_title > h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
}
