.boxCard {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    z-index: 5;
}
.boxCard > div:first-child {
    height: 400px;
    width: 100%;
    background-color: gray;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
}
.boxCard_info {
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    width: 100%;
    height: 100%;
    border: 0.5px solid #1cc09b;
}
.boxCard_info h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #276c77;
    text-align: center;
    padding: 4% 0 8% 0;
}
.boxCard_info > p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    color: #2b383b;
    text-align: center;
}
.boxCard_info > button {
    margin-top: 15%;
}

@media only screen and (min-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .boxCard_info h3 {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }
    .boxCard_info > p {
        font-size: 16px;
        line-height: 20px;
        color: #2b383b;
        margin: 0;
        padding: 0;
    }
}
@media only screen and (max-width: 767px) {
    .boxCard {
        margin-bottom: 40px;
    }
    .boxCard > div:first-child {
        height: 260px;
    }
    .boxCard_info {
        padding: 5%;
        gap: 5px;
    }
    .boxCard_info h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }
    .boxCard_info > p {
        font-size: 16px;
        line-height: 20px;
        color: #2b383b;
    }
    .boxCard_info > button {
        margin-top: 5%;
    }
}
@media only screen and (max-width: 576px) {
}
