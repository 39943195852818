.product_section {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
}
.product_boxLeftTitle {
    grid-column: 1;
    grid-row: 1;
    padding-left: var(--size-xxhuge);
    padding-right: var(--size-huge);
    box-sizing: border-box;
    overflow: hidden;
}
.productCommutieLogo_IMG {
    height: 9rem;
    width: auto;
    margin-bottom: var(--size-medium);
}
.product_boxLeft {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: var(--size-xxhuge);
    padding-right: var(--size-huge);
}

.product_boxLeft_Millenium {
    display: flex;
    flex-direction: column;
    padding-top: var(--size-large);
}
.product_boxLeft_Millenium > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.product_boxLeft_Millenium > div:nth-child(1) {
    align-items: center;
    padding-bottom: var(--size-large);
}
.product_boxLeft_Millenium > div > div:nth-child(1) {
    padding-right: var(--size-large);
}
.product_boxLeft_Millenium > div > div:nth-child(1) img {
    height: 6rem;
}
.product_boxLeft_Millenium > div > div:nth-child(2) h6 {
    font-weight: bold;
    font-size: 2rem;
}
.product_boxLeft_Millenium > div > div:nth-child(2) p {
    font-size: 1.7rem;
    line-height: 2.4rem;
}
/*.product_boxLeftButton{
    grid-column: 1;
    grid-row: 4;
    padding-top:var(--size-large);
    padding-left: var(--size-xxhuge);
}*/
.product_boxRight {
    grid-column: 2;
    grid-row: 1 / span 2;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: var(--size-xhuge);
    padding-right: var(--size-xhuge);
    margin: 0;
}
.product_boxBoxes {
    grid-column: 1/3;
    grid-row: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: var(--size-xhuge);
    padding-left: var(--size-xxhuge);
    padding-right: var(--size-xxhuge);
    padding-bottom: var(--size-large);
    box-sizing: border-box;
    overflow: hidden;
}
.product_boxBoxes > div {
    width: 30%;
    background-color: var(--color-greenLight);
    box-shadow: -2px 4px 45px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
}
/*fondo*/
.productBackRightTop_IMG {
    height: 285px;
    position: absolute;
    top: 10%;
    right: 0;
    z-index: -1;
}
.productBackRightDown_IMG {
    height: 80px;
    position: absolute;
    bottom: 20%;
    right: 42px;
    z-index: -1;
}
.problemBackLeftTop_IMG {
    height: 75px;
    position: absolute;
    top: 150px;
    left: 50px;
    z-index: -1;
}
.productBackLeftDownFull_IMG {
    height: 160px;
    position: absolute;
    bottom: 40%;
    left: -40px;
    z-index: -1;
}
.productBackLeftDown_IMG {
    position: absolute;
    bottom: -185px;
    left: 16px;
    width: 285px;
    z-index: -1;
}

/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .product_section {
        grid-template-columns: 3fr 2fr;
        grid-auto-rows: auto;
    }
    .product_boxLeftTitle {
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
    }
    .productCommutieLogo_IMG {
        height: 9rem;
    }
    .product_boxLeft {
        padding-left: var(--size-huge);
    }

    .product_boxRight {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }
    .product_boxBoxes {
        padding-top: var(--size-huge);
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
        padding-bottom: var(--size-small);
    }

    /*fondo*/
    .problemBackLeftTop_IMG {
        display: none;
    }
    .productBackLeftDown_IMG {
        position: absolute;
        bottom: -130px;
        left: 16px;
        width: 200px;
    }
}
/*TABLET*/
@media only screen and (max-width: 1024px) {
    .product_section {
        grid-template-columns: 4fr 2fr;
    }
    .product_boxLeftTitle {
        padding-top: var(--size-small);
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }
    .productCommutieLogo_IMG {
        height: 8rem;
    }
    .product_boxBoxes {
        justify-content: center;
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }
    .product_boxBoxes > div {
        width: 47%;
        box-sizing: border-box;
    }
    .product_boxBoxes > div:nth-child(1) {
        margin-right: 3%;
        margin-bottom: 6%;
    }
    .product_boxBoxes > div:nth-child(2) {
        margin-left: 3%;
        margin-bottom: 6%;
    }
    .product_boxLeft {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }

    .product_boxLeft_Millenium > div:nth-child(1) {
        padding-bottom: var(--size-small);
    }
    .product_boxLeft_Millenium > div > div:nth-child(2) h6 {
        font-size: 1.6rem;
    }
    .product_boxLeft_Millenium > div > div:nth-child(2) p {
        font-size: 1.3rem;
        line-height: 2.1rem;
    }
    .product_boxRight {
        padding-top: var(--size-small);
        padding-left: 0;
        padding-right: 0;
    }
    /*fondo*/
    .productBackRightTop_IMG {
        height: 215px;
        top: 75%;
    }
    .productBackRightDown_IMG {
        display: none;
    }
    .productBackLeftDownFull_IMG {
        height: 65px;
        bottom: 15%;
        left: 10%;
    }
    .productBackLeftDown_IMG {
        bottom: -95px;
        left: 16px;
        width: 143px;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .product_section {
        grid-template-columns: 1fr;
    }
    .product_boxLeftTitle {
        grid-column: 1;
        grid-row: 1;
        padding-top: var(--size-small);
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        text-align: center;
    }
    .productCommutieLogo_IMG {
        height: 7rem;
    }
    .product_boxLeft {
        grid-column: 1;
        grid-row: 3;
        padding-top: var(--size-large);
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-bottom: var(--size-large);
    }
    .product_boxLeft_Millenium > div > div:nth-child(2) h6 {
        font-size: 1.8rem;
    }
    .product_boxLeft_Millenium > div > div:nth-child(2) p {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    .product_boxRight {
        grid-column: 1;
        grid-row: 2;
        padding-right: var(--size-xxhuge);
        padding-left: var(--size-xxhuge);
    }
    .product_boxBoxes {
        grid-column: 1;
        grid-row: 4;
        justify-content: center;
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .product_boxBoxes > div {
        width: 100%;
    }
    .product_boxBoxes > div:nth-child(1) {
        margin-right: 0;
        margin-bottom: 6%;
    }
    .product_boxBoxes > div:nth-child(2) {
        margin-left: 0;
        margin-bottom: 6%;
    }
    /*fondo*/
    .productBackRightTop_IMG,
    .productBackRightDown_IMG,
    .problemBackLeftTop_IMG,
    .productBackLeftDownFull_IMG,
    .productBackLeftDown_IMG {
        display: none;
    }
}
@media only screen and (max-width: 576px) {
    .productCommutieLogo_IMG {
        height: 5rem;
        margin-bottom: 0;
    }
    .product_boxLeft {
        padding-top: var(--size-large);
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-bottom: 0;
    }
    .product_boxRight {
        padding-top: var(--size-medium);
        padding-right: var(--size-huge);
        padding-left: var(--size-huge);
        padding-bottom: var(--size-small);
    }

    .product_boxBoxes {
        justify-content: center;
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
    }
    .product_boxBoxes > div {
        width: 100%;
    }
}
