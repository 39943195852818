.button {
    color: var(--color-white);
    border-radius: 6px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-style: normal;
    align-self: center;
    background-color: var(--color-black);
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    font-size: 2rem;
}
.buttonCookies {
    margin-left: 1rem;
    background-color: var(--color-greenDark);
}
.buttonGreen {
    border: none;
    background-color: var(--color-greenDark);
    color: black;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.buttonGreenNav {
    border: none;
    background-color: var(--color-greenDark);
    color: black;
    border-radius: 6px;
    cursor: pointer;
}
.button:hover,
.buttonGreen:hover,
.buttonGreenNav:hover {
    background-color: #0f5d4c;
    color: white;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1024px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        font-size: 1.7rem;
        line-height: 1.7rem;
    }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .button {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 4.5rem;
        padding-right: 4.5rem;
        font-size: 3rem;
        line-height: 3rem;
    }
}
@media only screen and (max-width: 768px) {
    .button {
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.7rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .button {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 4rem;
        padding-right: 4rem;
        font-size: 2.6rem;
        line-height: 2.6rem;
    }
}
@media only screen and (max-width: 576px) {
    .button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }
}
@media only screen and (max-width: 350px) {
    .button {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
}
