.contentPolicies {
    padding-top: 80px;
    text-align: justify;
    font-family: 'Mulish' !important;
}

.contentPolicies h2 {
    font-weight: 800;
    padding: 1rem 0 2rem 0;
    font-size: 5rem;
    text-transform: uppercase;
}

.contentPolicies h3 {
    font-weight: 500;
    font-size: 2.8rem;
    padding: 6rem 0 1rem 0;
    text-transform: uppercase;
}

.contentPolicies h6 {
    font-weight: 600;
    font-size: 1.8rem;
    padding: 3rem 0 0 5rem;
    text-transform: uppercase;
}

.contentPolicies p {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 2rem 0 0 5rem;
}
.contentPolicies a,
.contentPolicies LinkText {
    cursor: pointer;
    color: blue;
    font-size: 1.6rem;
    line-height: 2.6rem;
}
.contentPolicies li {
    list-style-type: disc;
    list-style-position: inside;
    text-align: justify;
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 1.5rem 0 0 8rem;
}

.contentPolicies span {
    font-weight: bold;
}
.contentPolicies table {
    width: 100%;
    padding: 2% 0 0 3%;
}
.contentPolicies table th {
    font-size: 1.6rem;
    padding: 1% 1%;
    border-bottom: 1px solid #3b9ac9;
}

.contentPolicies table td {
    font-size: 1.4rem;
    width: auto;
    padding: 1%;
    border-bottom: 0.5px dashed #3b9ac9;
    text-align: left;
}
@media only screen and (min-width: 1400px) {
}

@media only screen and (max-width: 1024px) {
    .contentPolicies {
        padding-top: 116px;
    }
    .contentPolicies h2 {
        padding: 1rem 0 2rem 0;
        font-size: 4rem;
    }

    .contentPolicies h3 {
        font-size: 2rem;
        padding: 4rem 0 1rem 0;
    }

    .contentPolicies h6 {
        font-size: 1.8rem;
        padding: 5rem 0 0 0;
    }

    .contentPolicies p {
        font-size: 1.7rem;
        line-height: 2.7rem;
        padding: 2rem 0 0 5rem;
    }

    .contentPolicies li {
        font-size: 1.7rem;
        line-height: 2.7rem;
        padding: 2rem 0 0 5rem;
    }
    .contentPolicies table th {
        font-size: 1.7rem;
        padding: 1% 0;
    }

    .contentPolicies table td {
        font-size: 1.6rem;
    }
}
@media only screen and (max-width: 767px) {
    .contentPolicies {
        padding-top: 56px;
    }
    .contentPolicies h2 {
        padding: 1rem 0 1rem 0;
        font-size: 3rem;
    }

    .contentPolicies h3 {
        font-size: 2rem;
        padding: 3rem 0 1rem 0;
    }

    .contentPolicies h6 {
        font-size: 1.6rem;
        padding: 3rem 0 0 0;
    }

    .contentPolicies p {
        font-size: 1.6rem;
        line-height: 2.6rem;
        padding: 2rem 0 0 0;
    }

    .contentPolicies li {
        font-size: 1.6rem;
        line-height: 2.6rem;
        padding: 2rem 0 0 5rem;
    }
    .contentPolicies table th {
        font-size: 1.6rem;
        padding: 1% 0;
    }

    .contentPolicies table td {
        font-size: 1.5rem;
    }
}
@media only screen and (max-width: 576px) {
    .contentPolicies h2 {
        padding: 1rem 0 1rem 0;
        font-size: 2.6rem;
    }

    .contentPolicies h3 {
        font-size: 1.8rem;
        padding: 2rem 0 1rem 0;
    }

    .contentPolicies h6 {
        font-size: 1.5rem;
        padding: 2rem 0 0 0;
    }

    .contentPolicies p {
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding: 2rem 0 0 0;
    }

    .contentPolicies li {
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding: 2rem 0 0 3rem;
    }
    .contentPolicies table th {
        font-size: 1.4rem;
        padding: 1% 0;
    }

    .contentPolicies table td {
        font-size: 1.4rem;
    }
}
