.contact_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}
.contact_title {
    grid-column: 1/3;
    grid-row: 1;
    padding-bottom: var(--size-huge);
}
.contact_boxLeft {
    grid-column: 1;
    grid-row: 2;
    padding-left: var(--size-xhuge);
    padding-right: var(--size-huge);
    padding-bottom: var(--size-small);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact_boxRight {
    grid-column: 2;
    grid-row: 2;
    padding-left: var(--size-huge);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactBackLeft_IMG {
    position: absolute;
    height: 600px;
    opacity: 0.4;
    left: 0;
    bottom: 30px;
}
.contactBackRight_IMG {
    position: absolute;
    height: 200px;
    right: 54px;
    top: 18%;
    z-index: -1;
}
/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {
    .contact_boxLeft {
        padding-left: var(--size-xhuge);
        padding-right: var(--size-small);
        padding-bottom: var(--size-large);
    }
    .contact_boxRight {
        padding-left: var(--size-large);
    }
    .contactBackLeft_IMG {
        position: absolute;
        height: 600px;
        opacity: 0.4;
        left: -90px;
        bottom: 30px;
    }
    .contactBackRight_IMG {
        height: 170px;
        right: -30px;
        top: 10%;
    }
}

/*TABLET*/
@media only screen and (max-width: 1024px) {
    .contact_boxLeft {
        padding-left: var(--size-large);
        padding-right: var(--size-small);
        padding-bottom: var(--size-huge);
    }
    .contact_boxRight {
        padding-left: var(--size-medium);
    }
    .contactBackLeft_IMG {
        position: absolute;
        height: 530px;
        opacity: 0.4;
        left: -160px;
        bottom: 30px;
    }
    .contactBackRight_IMG {
        display: none;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .contact_section {
        display: grid;
        grid-template-columns: 1fr;
    }
    .contact_title {
        grid-column: 1;
        grid-row: 1;
        padding-bottom: var(--size-medium);
    }
    .contact_boxLeft {
        grid-column: 1;
        grid-row: 3;
        padding-left: var(--size-huge);
        padding-right: 0;
        padding-top: var(--size-large);
        padding-bottom: var(--size-large);
    }
    .contact_boxRight {
        grid-column: 1;
        grid-row: 2;
        padding-left: 0;
    }
    .contactBackLeft_IMG {
        position: absolute;
        height: 220px;
        opacity: 0.4;
        left: -60px;
        bottom: 20px;
    }
}
/*MINI MOBILE*/
@media only screen and (max-width: 576px) {
    .contact_boxLeft {
        grid-column: 1;
        grid-row: 3;
        padding-left: var(--size-large);
        padding-right: 0;
        padding-top: var(--size-large);
        padding-bottom: var(--size-small);
    }
    .contact_boxLeft p {
        font-size: 1.6rem !important;
        line-height: 2rem;
    }
    .contact_boxRight {
        grid-column: 1;
        grid-row: 2;
        padding-left: 0;
    }
    .contactBackLeft_IMG {
        position: absolute;
        height: 200px;
        opacity: 0.4;
        left: -60px;
        bottom: 20px;
    }
}
