.public_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(auto, auto, auto, auto);
    padding-left: var(--size-xxhuge);
    padding-right: var(--size-xxhuge);
}
.public_boxTitle {
    grid-column: 1/3;
    grid-row: 1;
    padding-bottom: var(--size-huge);
}
.public_boxImages {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: var(--size-small);
    box-sizing: border-box;
    overflow: hidden;
}
.public_boxText {
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 3;
    padding-left: var(--size-small);
}
.public_boxText h5 {
    margin-top: 0;
    padding-top: 0;
}
.public_boxText div:nth-child(2) {
    margin-top: -5%;
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.public_boxText div:nth-child(4) {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -5%;
}
.public_boxText div:nth-child(6) {
    grid-column: 1;
    grid-row: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -5%;
}
.public_boxText_imgMobile1,
.public_boxText_imgMobile2,
.public_boxText_imgMobile3 {
    display: none;
}
/*imagenes*/

.public_boxImg1 img,
.public_boxImg2 img,
.public_boxImg3 img {
    border-radius: 4px;
    max-height: auto;
    max-width: 80%;
}

.public_boxImg1 {
    text-align: right;
    width: 100%;
}
.public_boxImg2 {
    text-align: left;
    margin-top: -5%;
    width: 100%;
}
.public_boxImg3 {
    margin-top: -5%;
    text-align: right;
    width: 100%;
}

/*fondo*/
.publicBackLeftTop_IMG {
    width: 285px;
    position: absolute;
    top: 0;
    left: 17px;
}
.publicBackLeftMiddle_IMG {
    height: 80px;
    position: absolute;
    bottom: 100px;
    left: 37px;
}
.publicBackLeftDown_IMG {
    width: 160px;
    position: absolute;
    bottom: 0;
    left: 62px;
}
.publicBackRightTop_IMG {
    width: 85px;
    position: absolute;
    top: 76px;
    right: 121px;
}
.publicBackRightDown_IMG {
    width: 160px;
    position: absolute;
    top: 60%;
    right: 0;
}

/*MEDIA QUERYS*/
@media only screen and (max-width: 1400px) {
    .public_section {
        padding-left: var(--size-huge);
        padding-right: var(--size-huge);
        grid-template-columns: 1fr;
    }
    .public_boxImages {
        display: none;
    }
    .public_boxTitle {
        padding-bottom: var(--size-large);
    }
    .public_boxText {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(auto, auto, auto);
        padding: 0;
    }
    .public_boxText > div:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
    }
    .public_boxText > div:nth-child(3) {
        grid-row: 2;
        grid-column: 2;
    }
    .public_boxText > div:nth-child(4) {
        grid-row: 2;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(5) {
        grid-row: 3;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(6) {
        grid-row: 3;
        grid-column: 2;
    }
    .public_boxText div:nth-child(2),
    .public_boxText div:nth-child(4),
    .public_boxText div:nth-child(6) {
        padding: var(--size-large);
        margin-top: 0;
        padding-top: 25px;
    }
    .public_boxText div:nth-child(2) p,
    .public_boxText div:nth-child(4) p,
    .public_boxText div:nth-child(6) p {
        text-align: justify !important;
    }
    .public_boxText_imgMobile1 {
        background-image: url('assets/img/public_01.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: auto;
        display: block;
        z-index: 1;
        border-radius: 8px 8px 0 8px;
    }
    .public_boxText_imgMobile2 {
        background-image: url('assets/img/public_02.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: auto;
        display: block;
        z-index: 1;
        border-radius: 0 8px 8px 0;
    }
    .public_boxText_imgMobile3 {
        background-image: url('assets/img/public_03.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: auto;
        display: block;
        z-index: 1;
        border-radius: 8px 0 8px 8px;
    }
    .publicBackLeftTop_IMG {
        width: 200px;
    }
    .publicBackLeftMiddle_IMG {
        height: 80px;
    }
    .publicBackLeftDown_IMG {
        width: 160px;
        position: absolute;
        bottom: 0;
        left: 62px;
    }
    .publicBackRightTop_IMG {
        width: 75px;
    }
    .publicBackRightDown_IMG {
        width: 140px;
    }
}
/*TABLET*/
@media only screen and (max-width: 1024px) {
    .public_section {
        padding-left: var(--size-large);
        padding-right: var(--size-large);
    }
    .public_boxTitle {
        padding-bottom: var(--size-large);
    }

    /*fondo*/
    .publicBackLeftTop_IMG {
        width: 143px;
    }
    .publicBackLeftMiddle_IMG {
        height: 50px;
    }
    .publicBackLeftDown_IMG {
        width: 127px;
        left: 37px;
    }
    .publicBackRightTop_IMG {
        width: 45px;
        top: 48px;
        right: 75px;
    }
    .publicBackRightDown_IMG {
        width: 100px;
    }
}
/*MOBILE*/
@media only screen and (max-width: 767px) {
    .public_section {
        padding: 0;
    }
    .public_boxTitle {
        padding-bottom: var(--size-medium);
    }
    .public_boxText {
        padding-left: 0;
        padding-right: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(auto, auto, auto, auto, auto, auto, auto);
    }
    .public_boxText > div:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(3) {
        grid-row: 3;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(4) {
        grid-row: 4;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(5) {
        grid-row: 5;
        grid-column: 1;
    }
    .public_boxText > div:nth-child(6) {
        grid-row: 6;
        grid-column: 1;
    }
    .public_boxText div:nth-child(2),
    .public_boxText div:nth-child(4),
    .public_boxText div:nth-child(6) {
        padding-left: var(--size-medium);
        padding-right: var(--size-medium);
        padding-top: var(--size-medium);
        padding-bottom: var(--size-medium);
    }
    .public_boxText div:nth-child(2) p,
    .public_boxText div:nth-child(4) p,
    .public_boxText div:nth-child(6) p {
        text-align: justify !important;
    }
    .public_boxText_imgMobile1 {
        height: 200px;
        border-radius: 0;
    }
    .public_boxText_imgMobile2 {
        height: 200px;
        border-radius: 0;
    }
    .public_boxText_imgMobile3 {
        height: 200px;
        border-radius: 0;
    }
    /*fondo*/
    .publicBackLeftTop_IMG,
    .publicBackLeftMiddle_IMG,
    .publicBackLeftDown_IMG,
    .publicBackRightTop_IMG,
    .publicBackRightDown_IMG {
        display: none;
    }
}
@media only screen and (max-width: 576px) {
}
