.swiper {
    width: auto !important;
    height:auto !important;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 0!important;
    position: relative;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
}

.swiper-slide img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    padding: 3rem 0 0  0;
    box-sizing: border-box;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px !important;
}
/*MEDIA QUERYS*/
/*LAPTOP*/
@media only screen and (max-width: 1400px) {   
    
}
/*TABLET*/
@media only screen and (max-width: 1024px) {   
    .swiper-slide img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        padding: 0 0 0  0;
        box-sizing: border-box;
    }

}
/*MOBILE*/
@media only screen and (max-width: 767px) {
   
}
@media only screen and (max-width: 576px) {
}
